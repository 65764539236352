@use 'global/icons';
@import '../../../scss/shell';

:global {
    /**
	* PROFILE menu
	* - Account Data (Introduction, Willingness, Language)
	* - Personal Data (Personal Data, PornStar properties Languages)
	* - Privacy (Country ban)
	*/
    .privacySettingsContainer .separatorWithText p {
        @include _on('mobile') {
            line-height: 1;
            margin-left: 0;
            padding-left: 0;
        }
    }

    .separatorWithText {
        position: relative;
        color: var(--box--separator-text);
        font-size: 1.6rem;
        line-height: 60px;
        width: 100%;
        margin: 0 0 8px 0;
        padding: 0 30px;

        p {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 30px;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            background-color: var(--box--main-bg);
            padding: 0 20px 0 20px;
            margin-left: 10px;
            line-height: 60px;
        }
    }

    //Tabs
    .profilePageContainer {
        [data-react-component='PersonalDataTabs'] {
            margin: 0;
        }
    }

    //Introduction
    #introductionsettings_index {
        .introductionDescription {
            @include style('text', 'largeSize', 'regularColor');

            margin: 30px 30px 10px;
        }

        #profileIntroduction {
            display: none;
        }

        form {
            position: relative;
            margin: 40px;

            textarea {
                height: 94px;

                @-moz-document url-prefix() {
                    scrollbar-width: thin;
                    scrollbar-color: var(--scrollpane--scrollbar-bg) transparent;
                }

                &::-webkit-scrollbar {
                    width: 17px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    width: 7px;
                    border-radius: 7px;
                    border: 6px solid transparent;
                    transition: opacity 0.2s linear;
                    background: var(--scrollpane--scrollbar-bg);
                    background-clip: padding-box;
                }
            }
        }
    }

    //Per minute price
    #pricesettings_index,
    #pricesettings_pricesettings {
        .two-way-audio-settings .two-way-audio-price {
            margin-top: 0;
        }

        label.title {
            width: auto;
            float: left;
            line-height: 1.9;
            @include _on('mobile') {
                text-align: center;
                float: initial;
                padding: 0 0 40px;
            }
        }
        .inline-inputs {
            label {
                text-align: left;
            }
            form-input[type='toggle'] {
                text-align: right;
                label {
                    width: 60px;
                    height: 26px;
                    display: inline-block;
                    border-radius: 15px;
                }
            }
        }

        .settings-content {
            padding: 0 3rem;
            @include _on('mobile') {
                padding: 0 2rem;
            }

            form {
                margin: 20px 0 40px;
            }
        }

        .checkbox-container {
            width: auto;
            padding: 0 0 20px;
            display: inline-block;
            float: left;
            form-input {
                width: auto;
            }
            form-input,
            label {
                float: left;
                line-height: 1.8;
            }
            .language-title {
                width: auto;
                padding: 0 25px 0 20px;
            }
            &:last-child {
                .language-title {
                    padding: 0 0 0 20px;
                }
            }
        }

        @include _on('mobile') {
            .profilePageContainer {
                form {
                    margin: 0 0 30px;
                }

                .selectContainer {
                    width: 110px;

                    select {
                        visibility: visible;
                    }

                    .icon-info-circle {
                        display: none;
                    }
                }

                .checkbox-container {
                    width: 100%;
                }

                .two-way-audio-toggle {
                    position: absolute;
                    right: 0;
                    top: -45px;
                }
            }
        }
    }

    //Willingness
    #willingnesssettings_index {
        .willingnessDescription {
            @include style('text', 'largeSize', 'regularColor');

            margin: 30px 30px 10px;
        }

        .pageBottomContainer {
            width: 100%;
            text-align: center;
            margin: 30px 0 30px 0;

            .secondaryLarge {
                position: relative;
                top: 0;
            }

            i.icon-info-circle {
                position: relative;
                right: -5px;
                top: 3px;
            }
        }
    }

    #modelNature {
        padding: 0 30px;

        .modelNatureTitle {
            width: 100%;
            float: left;
            position: relative;
            color: var(--text--passive);
            font-size: 16px;
            line-height: 60px;
            margin: 0 0 8px 0;

            p {
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
                line-height: 60px;
                padding: 0 20px 0 0;
                background-color: var(--box--main-bg);
            }

            hr {
                margin-top: 30px;
            }
        }
    }

    // Profile -> Price & Privacy -> Privacy (Country ban, guest ban)
    .privacySettingsContainer,
    .profilePageContainer {
        padding: 0 0 30px !important;
        text-align: center;

        .tabWrapper {
            height: 5.4rem;
        }

        .ph-box__content {
            padding: 1.6rem 4.8rem;
            @include _on('mobile') {
                padding: 0.6rem 2.4rem;
            }
        }
        .separatorWithText {
            padding: 0;
            margin: 0;
        }

        .countryBanDescription {
            @include style('text', 'largeSize', 'regularColor');

            margin: 0 30px 35px;
            text-align: left;
        }

        .old-form label:not(.toggle-on-off) {
            width: auto;
            margin: 0 15px 0 0;
            text-align: left;
        }

        .mask {
            width: 100% !important;
        }

        .selectContainer[id^='select_'] {
            @include _on('mobile') {
                width: 97%;
            }
        }

        .inputs,
        .selectContainer,
        input {
            @include _on('mobile') {
                width: 100%;
            }
        }

        .inputs.switch {
            margin: 0;
            @include _on('mobile') {
                width: auto;
            }
        }

        p.info {
            display: block;
            margin: 1.5rem auto 2rem auto;
            font-size: 12px;
            color: var(--text--secondary);
        }

        p {
            text-align: left;
        }

        hr {
            margin-top: 30px;
        }

        .video-call-suspend-icon {
            position: relative;
            top: 2px;
            left: 5px;
            font-size: 20px;
            color: var(--text--link);
            cursor: pointer;
        }
    }

    .privacySettingsContainer {
        .ph-box__content {
            padding: 0 4.8rem;
        }
        .old-form label:not(.toggle-on-off) {
            width: 100%;
            height: auto;
            min-height: 34px;
            line-height: 1;
        }
    }

    .profilePageContainer {
        .wrapper {
            text-align: left;
        }
        .separatorWithText {
            padding: 0;

            &.more {
                p {
                    padding: 0 20px;
                }
            }
            p {
                padding: 0 20px 0 0;
                margin: 0;

                &.privateShow {
                    text-transform: lowercase;
                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
            &.more p {
                padding: 0 20px;
            }
        }
        label:not(.toggle-on-off) {
            width: auto;
            display: inline-block;
        }
        .selectContainer {
            display: inline-block;
        }
    }

    /*** Personal Data ***/
    #personalDataForm {
        padding: 2.4rem 4.8rem 4.8rem;
        margin: 0 !important;
        text-align: center;

        @include _on('mobile') {
            padding: 1.6rem 2.4rem 2.4rem;
        }

        #checkbox-isPornStar {
            padding: 0;
            position: absolute;

            &.chaeckbox-disabledActive,
            &.checkbox-disabledInactive {
                left: 0;
            }
        }

        .pageBottomContainer {
            width: 100%;
            text-align: center;
            margin: 1.6rem 0 0 0;
        }

        hr {
            margin-top: 30px;
        }

        .separatorWithText p {
            text-transform: capitalize;
        }
    }

    // Personal Data Properties
    #personaldata-properties {
        @include _flexbox-inline;
        @include _flexbox-wrap;
        @include _flexbox-justify-content(space-between);
        text-align: left;
        margin-right: -2rem;
        padding: 0;

        @include _on('mobile') {
            display: block;
            width: 100%;
            padding: 0 3rem 0 0;
        }

        > span {
            display: inline-block;
            width: 42.9rem;

            @include _on('mobile') {
                width: 100%;
            }
        }

        label {
            width: 205px;
            min-width: 205px;
            max-width: 205px;

            @include _on('mobile') {
                width: 100%;
                min-width: 0;
                max-width: none;
                text-align: left;
            }
        }

        .readOnlyContent,
        .inputs {
            width: 160px;

            @include _on('mobile') {
                width: 100%;
            }

            input,
            .selectContainer {
                @include _on('mobile') {
                    width: 100%;
                }
            }
        }

        #select_age {
            width: auto;
            margin-right: 58px;

            @include _on('mobile') {
                width: 100%;
            }
        }

        .isPornStarLabel {
            display: inline-block;
            float: none;
            vertical-align: top;
        }

        .pornStar {
            @include style('text', 'normalSize', 'highliteColor');
            position: relative;
            margin: 9px 0 20px;
            display: inline-block;
            float: none;
            vertical-align: top;

            input ~ i {
                top: 10px;
            }

            p {
                max-width: 530px;
                margin: 0 0 0 10px;
                padding: 3px 0 0 0;
                float: left;

                @include _on('mobile') {
                    max-width: calc(100% - 40px);
                }
            }
        }
    }

    // PornStar Properties
    #pornstar-properties {
        @include transition(0.5s, max-height, ease-out);
        max-height: 0;
        overflow: hidden;
        text-align: left;
        font-size: 0;
        width: 100%;
        padding: 0 100px 0 0;

        &.show {
            max-height: 1000px;
        }

        @include _on('mobile') {
            margin: 20px;
        }

        label {
            @include _flexbox-inline;
            @include _flexbox-justify-content(flex-end);
            width: 185px;
            min-width: 185px;
            max-width: 185px;
            font-size: 14px;

            @include _on('mobile') {
                display: block;
                width: 100%;
                text-align: left;
                min-width: 0;
                max-width: none;
            }
        }

        .inputs {
            @include _flexbox;
            width: auto;
            float: none;

            input,
            textarea {
                float: none;
                width: 100%;
            }

            @include _on('mobile') {
                display: block;
                float: left;
                width: 100%;
            }
        }

        // Inline rows
        #row_stageName,
        #row_numberOfFilms {
            @include _flexbox-inline;
            @include _flexbox-justify-content(flex-start);
            display: inline-flex;
            width: 50%;
            float: none;

            @include _on('mobile') {
                display: block;
                width: 100%;
            }
        }

        #row_numberOfFilms {
            @include _on('small', 'up') {
                @include _flexbox-justify-content(flex-end);
            }
        }

        @at-root :global .ext-mac #pornstar-properties #row_numberOfFilms {
            @include _flexbox-justify-content(flex-end);
        }

        .charCounter {
            display: none;
        }
    }

    .two-way-audio-price {
        float: left;
    }

    .spokenLanguagesLimit {
        margin-bottom: 2rem;
        font-size: 1.4rem;
        line-height: 2rem;
        color: var(--text--paragraph);

        strong {
            color: var(--text--base);
        }
    }

    .spokenLanguages {
        .checkBoxContainer {
            width: 20%;
            min-width: 15rem;

            @include _on('mobile') {
                width: 50%;
            }

            .checkbox-disabledActive,
            .checkbox-disabledInactive {
                left: 0;
            }
            label {
                width: auto;
                text-transform: lowercase;

                &:first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }

    #personalDataEmptyContainer {
        @include style('box', 'common');

        text-align: center;

        img {
            margin: 40px 0;
            width: 200px;
            height: auto;
        }

        > p {
            @include style('text', 'hugeSize', 'passiveColor');
            color: var(--text--base);
            font-weight: bold;
            font-size: 26px;
            line-height: 40px;
        }
    }

    #clothing-properties {
        label {
            width: 20.5rem;
        }
        @include _on('mobile') {
            padding: 0 3rem 0 0;
        }
    }

    #introduction-properties {
        label {
            width: 20.5rem;
        }
        @include _on('mobile') {
            padding: 0 3rem 0 0;
        }
    }

    #age-component-dropDown,
    #age-component-slide,
    #height-component-dropDown,
    #height-component-slide {
        @include _on('mobile') {
            width: 100%;
        }
    }

    #personaldatasettings_index .common-info-tooltip[data-pt-position='top-right'] .protip-arrow {
        right: 1rem;
    }

    #personaldata-fullname {
        text-align: left;
        font-size: 1.4rem;
        margin: 2.3rem 0 1.5rem;
    }

    @import 'Profile.oranum';
}
