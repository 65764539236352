@import '../../../scss/shell';

:global {
	#earnMoreReferralContent {
		padding: 40px;

		.old-form {
			text-align: left;
		}

		.referralLink {
			margin: 13px 0 35px 0;
			color: var(--referral--link);
			width: 100%;
		}

		.bold {
			font-weight: bold;
		}

		.customLineHeight {
			line-height: 24px;
		}

		.highlightedHugeText {
			color: var(--text--secondary);
		}

		.formBox {
			padding: 20px;
			border-radius: 5px;
			background: var(--referral--form-bg);
		}

		form#memberReferralSendEmail {
			text-align: right;

			label,
			input {
				width: auto;
			}
			textarea {
				width: 100%;
			}
			.charCounter {
				left: 0;
				top: 86px;
			}

			.table_row {
				display: table;
				width: 100%;
			}

			#row_message {
				display: table;
				width: 100%;
			}

			.form_row {
				display: table-row;

				> * {
					display: table-cell;
					float: none;
					height: auto;
					vertical-align: middle;
					text-align: left;
				}

				label {
					width: 0;
					white-space: nowrap;
					padding-right: 15px;
				}

				input {
					width: 100%;
				}
			}

			.sendButton {
				margin: 20px 0 0;
			}

			#input_referred_member_email {
				height: 71px;
			}
		}

		.hr {
			text-align: left;
			width: 100%;
			margin-top: 36px;

			span {
				color: var(--text--base);
				padding-left: 0;
				text-transform: none;
			}

		}

		form#memberReferralStatisticsForm {
			#searchTooltipWrapper {
				float: left;
			}
			// Custom advancedSelect widths
			#select_percentLevel,
			#select_searchedStatus {
				input {
					width: 125px;
				}
				.dropDownContainer {
					width: 105px;
				}
			}

			#select_searchedPeriod {
				input {
					width: 210px;
				}
				.dropDownContainer {
					width: 180px;
				}
			}

			.selectArrow {
				top: 4px;
				right: 4px;
			}
			.inputs {
				margin: 0 10px 0 0;
			}
			// Reset filters button
			.secondaryLarge {
				float: right;
			}

			.clear {
				// Im so sorry :((
				float: left;
			}
		}

		table {
			margin: 10px 0 0;
			padding: 0;
			display: table;
			width: 100%;

			td {
				text-align: center;

				&:first-child {
					text-align: left;
					position: relative;
				}
			}

			// Name fields
			.tableRegularCell {
				color: var(--text--link);
			}

			.tableHighlightedCell {
				font-weight: normal;
			}

			// Column widths
			.name {
				width: 230px
			}
			.join {
				width: 105px
			}
			.status {
				width: 105px
			}
			.level {
				width: 105px
			}
			.income {
				width: 113px
			}

			tbody {
				/* We have custom cell bg color in the PSD yay ! */
				td {
					background-color: var(--table--dark-bg);
				}

				display: table-header-group;
				width: 100%;
			}

			/* Period Income right align */
			tfoot .tableSummarizeLastCell span {
				position: absolute;
				right: 20px;
			}

			.showMoreRow td {
				text-align: center;
				padding-top: 7px;
				padding-bottom: 7px;
			}

			.noReferredMembers td {
				text-align: center;
				padding: 7px 0;
			}
		}
	}

	/* Referral Page */
	.earnMoreReferral {
		@include extendEarnMoreStatusHeader;
	}
}
